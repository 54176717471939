import React from 'react';

function Footer() {
  return (
    <footer className='w-full bg-primary p-2 top-20 relative'>
      
    </footer>
  );
}

export default Footer;