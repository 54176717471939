import React from 'react';

function Search({ onChange, value }) {
  return (
    <section className='flex flex-col my-2 p-2 items-end'>
      <label className='text-sm text-gray-400' htmlFor="search">Cari layanan atau paket</label>
      <input value={value} onChange={(e) => onChange(e.target.value)} className='border border-gray-100 p-2 px-4 rounded-full text-primary w-full md:w-1/2 xl:w-1/4' type="text" name="search" id="search" placeholder='contoh: massage' />
    </section>
  );
}

export default Search;