import React from 'react';

function Header() {
  return (
    <header className='w-full bg-primary p-2 fixed top-0 z-10'>
      <a href="https://permadanihomespa.com">
        <h1 className='ruthie text-white text-2xl text-center lg:text-5xl font-bold underline'>Permadani Home Spa</h1>
      </a>
    </header>
  );
}

export default Header;