import React, { useState } from 'react';
import Search from '../components/Search';
import Services from '../components/Services';
 
function Contents(props) {
  const [search, setSearch] = useState("");
  return (
    <div id='order' className='min-h-screen w-full md:w-8/12 m-auto relative'>
      <Search value={search} onChange={setSearch} />
      <Services filterName={search} />
    </div>
  );
}

export default Contents;