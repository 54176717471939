import React from 'react';
import { currencyFormatter } from '../helpers/currencyFormatter';
import logo from '../images/icon.png';

function Service({ data, title, selected, setSelected }) {
  const handleAdd = (name) => {
    setSelected([...selected, name]);
  }

  const handleRemove = (name) => {
    setSelected(selected.filter(s => s !== name));
  }

  return (
    <div className='p-2 border-b border-gray-100'>
      <p className='text-primary text-lg'>{title}</p>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
        {
          data.map(datum => {
            return <div className='shadow-md shadow-primary p-2 rounded flex flex-col' key={datum.name}>
              <div className='flex flex-row justify-between items-center'>
                <p className='text-sm text-primary'>{datum.name}</p>
                <img className='w-6 h-6' alt='Logo Permadani Home Spa' src={logo}/>
              </div>
              <div className='flex flex-col'>
                {datum.plan.map(p => {
                  const id = datum.name + "|" + p.name + "|" + p.price;
                  const isSelected = selected.includes(id);
                  return (
                    <button key={id} onClick={() => isSelected ? handleRemove(id) : handleAdd(id)} className='flex flex-row w-full items-center border-b border-gray-100'>
                      <div className='p-2 text-xs flex-1 text-left'>
                        <p className='text-primary'>{p.name}</p>
                        <p className='italic'>{currencyFormatter("IDR", p.price * 1000)}</p>
                      </div>
                      <div className="flex-1 flex justify-end">
                        <input checked={isSelected} onChange={() => isSelected ? handleRemove(id) : handleAdd(id)} className='w-4 h-4' type="checkbox" name={id} id={id} />
                      </div>
                    </button>)
                })}
              </div>
            </div>
          })
        }
      </div>
    </div>
  );
}

export default Service;