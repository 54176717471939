import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Jumbotron from './components/Jumbotron';
import Contents from './contents/Contents';

function App() {
  return (
    <div className="App">
      <Header />
      <Jumbotron />
      <Contents />
      <Footer />
    </div>
  );
}

export default App;
