import React, { useState } from 'react';
import single from "../static/single.json"
import packages from "../static/packages.json"
import additional from "../static/additional.json"
import Service from './Service';
import Modal from './Modal';

function Services({ filterName = "" }) {
  const [selected, setSelected] = useState([])
  return (
    <div className='w-full'>
      <Service selected={selected} setSelected={setSelected} data={single.filter(s => s.name.includes(filterName))} title="Layanan" />
      <Service selected={selected} setSelected={setSelected} data={packages.filter(s => s.name.includes(filterName))} title="Paket" />
      <Service selected={selected} setSelected={setSelected} data={additional.filter(s => s.name.includes(filterName))} title="Tambahan" />

      <Modal data={[...single, ...packages, ...additional]} selected={selected} />
    </div>
  );
}

export default Services;