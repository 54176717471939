
const currencyFormatter = (currency, value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });

  return formatter.format(value);
};

export { currencyFormatter };
