import React, { useState } from 'react';
import { currencyFormatter } from '../helpers/currencyFormatter';

const enter = "%0A"

function Modal({ data, selected }) {
  const [open, setOpen] = useState(false);
  const [myName, setMyName] = useState("");
  const [address, setAddress] = useState("");
  const [room, setRoom] = useState("");
  const [time, setTime] = useState("");
  const [gender, setGender] = useState("");
  return (
    <div>
      {
        open && <button className='fixed inset-0 bg-primary bg-opacity-20 flex justify-center overflow-auto'>
          <div className="w-11/12 md:w-8/12 lg:w-1/2 py-44 z-50">
            <div className='w-full bg-white rounded shadow shadow-white min-h-full p-2 py-4'>
              <div className="flex flex-row border-b pb">
                <p className='text-base text-primary'>Konfirmasi Pesanan</p>
              </div>

              <div className="flex flex-col items-start my-2">
                <label htmlFor='yourName' className='text-xs italic text-gray-400'>Nama Anda</label>
                <input onChange={(e) => setMyName(e.target.value)} className='px-4 py-1 w-full text-primary text-sm rounded-lg shadow' type="text" id='yourName' name='yourName' />
              </div>

              <div className="flex flex-col items-start my-2">
                <label htmlFor='yourAddress' className='text-xs italic text-gray-400'>Alamat Anda</label>
                <textarea onChange={(e) => setAddress(e.target.value)} className='px-4 py-1 w-full text-primary text-sm rounded-lg shadow' type="text" id='yourAddress' name='yourAddress' />
              </div>

              <div className="flex flex-col items-start my-2">
                <label htmlFor='yourName' className='text-xs italic text-gray-400'>No Kamar</label>
                <input onChange={(e) => setRoom(e.target.value)} className='px-4 py-1 w-full text-primary text-sm rounded-lg shadow' type="text" id='yourName' name='yourName' />
              </div>
              
              <div className="flex flex-col items-start my-2">
                <label htmlFor='yourName' className='text-xs italic text-gray-400'>Theraphyst Gender</label>
                <input onChange={(e) => setGender(e.target.value)} className='px-4 py-1 w-full text-primary text-sm rounded-lg shadow' type="text" id='yourName' name='yourName' />
              </div>
              
              <div className="flex flex-col items-start my-2">
                <label htmlFor='yourName' className='text-xs italic text-gray-400'>Waktu / Time</label>
                <input type='datetime-local' onChange={e => setTime(e.target.value)} />
              </div>

              <div className='flex flex-col mt-4 gap-2'>
                {selected.map(s => {
                  const select = s.split("|");
                  const name = select[0];
                  const plan = select[1];
                  const price = select[2] * 1000;
                  return <div key={s} className='flex flex-row border-b items-end justify-between'>
                    <div className='text-left'>
                      <p className='text-sm text-primary'>{name}</p>
                      <p className='text-sm text-gray-400 italic'>{plan}</p>
                    </div>
                    <div>{currencyFormatter("IDR", price)}</div>
                  </div>
                })}
              </div>
              <div className="flex flex-row mt-4 gap-2">
                <div className='flex-1 rounded-full' style={{ backgroundColor: "#25D366" }}>
                  <a target='_blank' href={(() => {
                    let message = `Halo Permadani Home Spa, saya ${myName}, saya ingin memesan layanan dengan detail: ${enter}${enter}`
                    selected.forEach(s => {
                      const select = s.split("|");
                      const name = select[0];
                      const plan = select[1];
                      const price = select[2] * 1000;

                      const built = `Nama Layanan: ${name}${enter}Nama Paket: ${plan}${enter}Biaya: ${currencyFormatter("IDR", price)}${enter}`
                      message += built;
                    })

                    let anotherDetail = `${enter}Kamar Hotel (Hotel Room): ${room} ${enter}Theraphyst Gender: ${gender} ${enter}Waktu (Time): ${new Date(time).toLocaleString()} ${enter}${enter}`;
                    return "https://wa.me/6285885449854?text=" + message + anotherDetail + `Ditunggu di ${address} ya.`
                  })()} className='w-full rounded text-white text-center p-2 text-xs inline-block' rel="noreferrer">
                    Pesan dengan Whatsapp
                  </a>
                </div>
                <div className='flex-1 rounded-full'>
                  <button onClick={() => setOpen(false)} className='text-white text-center p-2 w-full bg-red-500 rounded-full text-xs'>Batal</button>
                </div>
              </div>
            </div>
          </div>
        </button>
      }
      <div class="fixed bottom-4 right-4 z-10">
        <p className='relative top-4 left-8 w-6 h-6 p-2 rounded-full bg-white text-xs border-primary border flex items-center justify-center'>
          {selected.length}
        </p>
        <button onClick={() => setOpen(true)} class="bg-primary hover:bg-opacity-90 text-white font-bold p-4 rounded-full shadow-lg flex items-center justify-center">
          <span class="material-symbols-outlined">
            shopping_cart
          </span>
        </button>
      </div>
    </div>
  );
}

export default Modal;