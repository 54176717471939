/* eslint-disable no-useless-concat */
import React from 'react';
import hero from "../images/hero.png"
import logo from "../images/icon.png"

function Jumbotron() {
  return (
    <div className='h-screen w-screen flex flex-col items-center justify-center' style={{
      backgroundImage: "url(" + hero + ")",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    }}>
      <div className="w-full h-full bg-black bg-opacity-30 flex flex-col items-center justify-center">
        <img className='w-20 h-20 md:w-44 md:h-44' alt='Logo Permadani Home Spa' src={logo} />
        <p className='text-white text-2xl'>Permadani Home Spa</p>
        <a className='inline-block p-2 rounded-full bg-white text-primary px-4 animate-pulse' href='#order'>
          Pilih Layanan
        </a>
      </div>
    </div>
  );
}

export default Jumbotron;